@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Roboto;
}

.flex {
  display: flex;
}

img {
  max-width: 100%;
}

h2.title {
  margin: 24px 0;
  color: #4d5e55;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
}
h2.title.go-back img {
  margin-top: -8px;
}

.no-auth-page form input[type='text'],
.no-auth-page form .ant-picker,
.no-auth-page form textarea.ant-input,
.no-auth-page form .ant-input-password,
.no-auth-page form .ant-input-number-input,
.no-auth-page form .ant-form-item .ant-cascader-picker,
.no-auth-page form .ant-form-item .ant-select,
.no-auth-page form .ant-input-number {
  max-width: unset;
  width: 100%;
  border-radius: 6px;
}

.order-info-page input[type='text'],
.order-info-page .ant-picker,
.order-info-page textarea.ant-input,
.order-info-page .ant-input-password,
.order-info-page .ant-input-number-input,
.order-info-page .ant-form-item .ant-cascader-picker,
.order-info-page .ant-form-item .ant-select,
.order-info-page .ant-select-selector,
.order-info-page .ant-input-number {
  width: 100%;
}

form label {
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
}
form .ant-form-item {
  margin-bottom: 16px;
}
form .ant-form-item .ant-form-item-label {
  padding-bottom: 1px;
}
form input[type='text'],
form .ant-picker,
form textarea.ant-input,
form .ant-input-password,
form .ant-input-number-input,
form .ant-form-item .ant-cascader-picker,
form .ant-form-item .ant-select,
form .ant-select-selector,
form .ant-input-number {
  max-width: unset;
  width: 96%;
  border-radius: 6px !important;
}
.ant-form-item-label {
  min-width: 120px;
  white-space: nowrap;
}
form .ant-form-item-control-input-content .ant-select-selector {
  width: 100%;
}
form
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 6px;
}
form .ant-checkbox-wrapper {
  font-size: 12px;
  line-height: 140%;
  width: 100%;
}
form .ant-form-item-has-success {
  margin-bottom: 10px;
}
form button {
  background-color: #eb7120;
  border: none;
}
form button:disabled,
form button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
form button img {
  width: 24px;
}

.clickable {
  cursor: pointer;
}

.btn-gp {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.btn {
  display: inline-block;
  cursor: pointer;
  border-radius: 2px;
  padding: 8px 60px;
  background: #eb7120;
  height: auto;
  color: white;
  border: none;
  margin: 5px;
}
.btn:hover {
  background: #eb7120;
  opacity: 0.9;
  color: white;
}
.btn:disabled,
.btn[disabled] {
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
}
.btn.outline {
  border: 1px solid #e01d11;
  background-color: white;
  color: #e01d11;
}
.btn.outline:hover {
  border: 1px solid #e01d11;
  background-color: white;
  color: #e01d11;
  opacity: 0.9;
}

.empty-table {
  min-height: 500px;
  width: 100%;
  height: 100vh;
  color: #4d5e55;
  display: flex;
  justify-content: center;
}
.empty-table .wrapper {
  width: auto;
  margin-top: 25vh;
  text-align: center;
}

.no-auth-page {
  height: 100vh;
  overflow: hidden;
}
.no-auth-page .sider {
  width: 23%;
  height: 100vh;
}
.no-auth-page form button {
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;
  margin-top: 30px;
  width: 100%;
  height: 60px;
  border-radius: 6px;
}
.no-auth-page .other {
  padding: 20px 110px;
  position: relative;
  flex: 1;
}
.no-auth-page .other .header {
  text-align: right;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
}
.no-auth-page .other .header .link a {
  color: #0e5130;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
}
.no-auth-page .other .content {
  height: 100%;
  width: 548px;
}
.no-auth-page .other .content .wrapper {
  align-self: center;
  width: 100%;
}
.w-60 {
  width: 60px !important;
  padding: 0px !important;
}
.no-auth-page .other .content .wrapper h1 {
  color: #4d5e55;
  font-weight: bold;
  font-size: 56px;
  line-height: 110%;
}
.no-auth-page .other .content .wrapper p {
  color: #5e5e5e;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
}

.avatar {
  position: absolute;
  top: 24px;
  right: 54px;
  padding: 0 20px;
  height: 30px;
  border-radius: 15px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: white;
  text-shadow: 0px 0px 2px #00000054;
}

#sign-in .forget a {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #4d4d4d;
}
#sign-in .ant-checkbox-wrapper span {
  color: #000000;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}
#sign-in .forget {
  justify-content: space-between;
}
#sign-in .forget .ant-form-item {
  width: 200px;
}
.ant-layout-sider {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important;
}
.ant-layout-sider-collapsed
  .ant-layout-sider-children
  #sider
  .ant-menu-item-icon
  img {
  display: inline-block;
  padding-bottom: 6px;
}
.ant-layout-sider-collapsed
  .ant-layout-sider-children
  #sider
  .ant-menu-item-icon {
  width: 30px;
}
.ant-layout-sider-collapsed .ant-layout-sider-children #sider .logo {
  width: 70px;
  margin-bottom: 60px;
}

#sider {
  background: #97be11;
  font-size: 17px;
  line-height: 140%;
  overflow: hidden;
  color: white;
}
#sider .ant-menu-item-icon {
  width: 30px;
}
#sider .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
  font-size: 16px;
}
#sider .logo {
  width: 90px;
  margin: 10px auto 40px auto;
}
#sider ul.ant-menu {
  border: none;
  color: white;
}
#sider ul.ant-menu li {
  background: #97be11;
  position: relative;
}
#sider ul.ant-menu li.ant-menu-item-selected .ant-menu-title-content * {
  font-weight: 900;
  font-size: 18px;
}
#sider ul.ant-menu li.ant-menu-item-selected .ant-menu-title-content *::before {
  opacity: 0;
  content: '';
  position: absolute;
  width: 17px;
  height: 24px;
  background: #4d5e55;
  left: 0;
  top: 8px;
  border-radius: 0 4px 4px 0;
}
#sider ul.ant-menu li .ant-menu-submenu-title {
  color: white;
  opacity: 1;
  font-size: 16px;
}
#sider ul.ant-menu li .ant-menu-submenu-title :hover {
  color: white;
}
#sider ul.ant-menu li .ant-menu-title-content * {
  color: white;
  opacity: 1;
}
#sider ul.ant-menu li ul.ant-menu-sub {
  list-style: inside;
  font-size: 12px;
  line-height: 140%;
  margin-bottom: 10px;
}
#sider ul.ant-menu li ul.ant-menu-sub li {
  margin: 0;
  height: 30px;
}
#sider ul .ant-menu-submenu {
  border: none;
  color: white;
}
#sider ul .ant-menu-submenu ul.ant-menu li.ant-menu-item-selected::before {
  content: '';
  position: absolute;
  width: 17px;
  height: 18px;
  background: #97be11;
  left: 0;
  top: 6px;
  border-radius: 0 4px 4px 0;
}
#sider ul .ant-menu-submenu ul.ant-menu .ant-menu-submenu-title:hover {
  color: white;
}
#sider ul .ant-menu-submenu ul.ant-menu .ant-menu-title-content * {
  color: white;
}

.ant-layout-sider-collapsed #sider .ant-menu-item-icon {
  width: 20px;
}
.ant-layout-sider-collapsed
  #sider
  .ant-menu-item-icon
  li.ant-menu-item-selected::before {
  content: '';
  position: absolute;
  width: 32px;
}

.ant-table {
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  border: 1px solid #55555545;
}
.ant-table .ant-table-container .ant-table-thead th {
  font-size: 12px;
  line-height: 140%;
  padding: 12px 16px;
  color: #c9c8d3;
  background: white;
}
.ant-table .ant-table-container .ant-table-tbody.ant-table-tbody tr td {
  font-size: 14px;
  line-height: 140%;
  color: #4d5e55;
  padding: 10px;
}
.ant-table
  .ant-table-container
  .ant-table-tbody.ant-table-tbody
  tr:nth-child(odd) {
  background: rgba(151, 190, 17, 0.1);
}
.ant-table
  .ant-table-container
  .ant-table-tbody.ant-table-tbody
  tr:nth-child(odd)
  .ant-table-cell.ant-table-column-sort {
  background: rgba(151, 190, 17, 0.1);
}

#users-page .cards {
  margin-bottom: 20px;
}
#users-page .cards .user-type-card {
  justify-content: space-between;
  padding: 24px;
  width: 318px;
  border-radius: 10px;
  color: #0e5130;
  background-color: white;
  margin-right: 24px;
  border: 1px solid #97be1155;
  box-shadow: 7px 7px 11px -5px rgba(0, 0, 0, 0.2);
}
#users-page .cards .user-type-card.active {
  background-color: #97be11;
  color: white;
}
#users-page .cards .user-type-card.active .type {
  color: white;
}
#users-page .cards .user-type-card .number {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
}
#users-page .cards .user-type-card .type {
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #4d5e55;
}

.info-page h2 img {
  height: 24px;
  margin-right: 10px;
}

.every-info {
  margin: 20px 0;
}
.every-info .title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* or 20px */
  display: flex;
  align-items: center;
  /* Text/Black */
  color: #000000;
}
.every-info .text {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */
  display: flex;
  align-items: center;
  /* Text/01 */
  color: #4d5e55;
}

.order-info .card {
  background-color: white;
  border-radius: 10px;
  padding: 16px;
}
.order-info .card .btn {
  margin-bottom: 5px;
}

.ant-btn-primary {
  background-color: #eb7120;
  border: none;
}
.ant-btn-primary:hover {
  background-color: #eb7120;
}
.ant-btn-primary[disabled] {
  background-color: #c1c1c1;
  color: white;
  border: none;
}

.ant-spin-container {
  position: static;
}
.ant-spin-container::after {
  position: static;
}

.ant-form-item-control-input,
.ant-spin-nested-loading {
  position: static;
}

.create-order-page {
  flex-wrap: nowrap;
}
.create-order-page .main,
.create-orderguide-page .main {
  flex: 1 1 auto;
  min-width: 300px;
  overflow: hidden;
}
.create-order-page .main .title img,
.create-orderguide-page .main .title img {
  height: 24px;
  margin-right: 10px;
}
.create-order-page .main .steps,
.create-orderguide-page .main .steps {
  margin-bottom: 30px;
}
.create-order-page .order-list,
.create-order-page .product-list,
.create-orderguide-page .order-list,
.create-orderguide-page .product-list {
  z-index: 10;
  flex: 0 0 300px;
  margin-right: -27px;
  margin-left: 20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.create-order-page .order-list > .title,
.create-order-page .product-list > .title,
.create-orderguide-page .order-list > .title,
.create-orderguide-page .product-list > .title {
  padding: 25px 16px;
  background-color: #4d5e55;
  color: white;
  border-radius: 0px 0px 5px 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
}
.create-order-page .order-list .products,
.create-order-page .order-list .orders,
.create-order-page .product-list .products,
.create-order-page .product-list .orders,
.create-orderguide-page .order-list .products,
.create-orderguide-page .order-list .orders,
.create-orderguide-page .product-list .products,
.create-orderguide-page .product-list .orders {
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
  flex-wrap: nowrap;
}
.create-order-page .order-list .products .product.card,
.create-order-page .order-list .products .order.card,
.create-order-page .order-list .orders .product.card,
.create-order-page .order-list .orders .order.card,
.create-order-page .product-list .products .product.card,
.create-order-page .product-list .products .order.card,
.create-order-page .product-list .orders .product.card,
.create-order-page .product-list .orders .order.card,
.create-orderguide-page .order-list .products .product.card,
.create-orderguide-page .order-list .products .order.card,
.create-orderguide-page .order-list .orders .product.card,
.create-orderguide-page .order-list .orders .order.card,
.create-orderguide-page .product-list .products .product.card,
.create-orderguide-page .product-list .products .order.card,
.create-orderguide-page .product-list .orders .product.card,
.create-orderguide-page .product-list .orders .order.card {
  position: relative;
  padding: 10px 5px;
  width: 95%;
  margin: 5px auto;
  background: rgba(151, 190, 17, 0.06);
  /* Stroke/Focused */
  border: 0.2px solid #97be11;
  box-sizing: border-box;
  border-radius: 6px;
}
.create-order-page .order-list .products .product.card .title,
.create-order-page .order-list .products .order.card .title,
.create-order-page .order-list .orders .product.card .title,
.create-order-page .order-list .orders .order.card .title,
.create-order-page .product-list .products .product.card .title,
.create-order-page .product-list .products .order.card .title,
.create-order-page .product-list .orders .product.card .title,
.create-order-page .product-list .orders .order.card .title,
.create-orderguide-page .order-list .products .product.card .title,
.create-orderguide-page .order-list .products .order.card .title,
.create-orderguide-page .order-list .orders .product.card .title,
.create-orderguide-page .order-list .orders .order.card .title,
.create-orderguide-page .product-list .products .product.card .title,
.create-orderguide-page .product-list .products .order.card .title,
.create-orderguide-page .product-list .orders .product.card .title,
.create-orderguide-page .product-list .orders .order.card .title {
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
}
.create-order-page .order-list .products .product.card .value,
.create-order-page .order-list .products .order.card .value,
.create-order-page .order-list .orders .product.card .value,
.create-order-page .order-list .orders .order.card .value,
.create-order-page .product-list .products .product.card .value,
.create-order-page .product-list .products .order.card .value,
.create-order-page .product-list .orders .product.card .value,
.create-order-page .product-list .orders .order.card .value,
.create-orderguide-page .order-list .products .product.card .value,
.create-orderguide-page .order-list .products .order.card .value,
.create-orderguide-page .order-list .orders .product.card .value,
.create-orderguide-page .order-list .orders .order.card .value,
.create-orderguide-page .product-list .products .product.card .value,
.create-orderguide-page .product-list .products .order.card .value,
.create-orderguide-page .product-list .orders .product.card .value,
.create-orderguide-page .product-list .orders .order.card .value {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
}
.create-order-page .order-list .products .product.card .remove,
.create-order-page .order-list .products .order.card .remove,
.create-order-page .order-list .orders .product.card .remove,
.create-order-page .order-list .orders .order.card .remove,
.create-order-page .product-list .products .product.card .remove,
.create-order-page .product-list .products .order.card .remove,
.create-order-page .product-list .orders .product.card .remove,
.create-order-page .product-list .orders .order.card .remove,
.create-orderguide-page .order-list .products .product.card .remove,
.create-orderguide-page .order-list .products .order.card .remove,
.create-orderguide-page .order-list .orders .product.card .remove,
.create-orderguide-page .order-list .orders .order.card .remove,
.create-orderguide-page .product-list .products .product.card .remove,
.create-orderguide-page .product-list .products .order.card .remove,
.create-orderguide-page .product-list .orders .product.card .remove,
.create-orderguide-page .product-list .orders .order.card .remove {
  position: absolute;
  padding: 5px 3px;
  top: -7px;
  right: 3px;
  opacity: 0.7;
  cursor: pointer;
}
.create-order-page .order-list .bottom,
.create-order-page .product-list .bottom,
.create-orderguide-page .order-list .bottom,
.create-orderguide-page .product-list .bottom {
  height: auto;
  flex-direction: column;
}
.create-order-page .order-list .bottom hr,
.create-order-page .product-list .bottom hr,
.create-orderguide-page .order-list .bottom hr,
.create-orderguide-page .product-list .bottom hr {
  width: 90%;
  border: none;
  border-bottom: 1px solid grey;
}
.create-order-page .order-list .bottom .price,
.create-order-page .product-list .bottom .price,
.create-orderguide-page .order-list .bottom .price,
.create-orderguide-page .product-list .bottom .price {
  padding: 2px 8px;
  display: flex;
  justify-content: space-between;
}
.create-order-page .order-list .bottom .price .title,
.create-order-page .product-list .bottom .price .title,
.create-orderguide-page .order-list .bottom .price .title,
.create-orderguide-page .product-list .bottom .price .title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
}
.create-order-page .order-list .bottom .price .value,
.create-order-page .product-list .bottom .price .value,
.create-orderguide-page .order-list .bottom .price .value,
.create-orderguide-page .product-list .bottom .price .value {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  /* Text/01 */
  color: #4d5e55;
}
.create-order-page .step2 button,
.create-orderguide-page .step2 button {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  width: 85px;
  height: 28px;
  padding: 0;
  margin: 0;
}
.create-order-page .step3 .order.card,
.create-orderguide-page .step3 .order.card {
  position: relative;
  max-height: 100px;
  padding: 10px 5px;
  width: 95%;
  margin: 15px auto;
  background: rgba(151, 190, 17, 0.06);
  /* Stroke/Focused */
  border: 0.2px solid #97be11;
  box-sizing: border-box;
  border-radius: 6px;
}
.create-order-page .step3 .order.card .title,
.create-orderguide-page .step3 .order.card .title {
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
}
.create-order-page .step3 .order.card .value,
.create-orderguide-page .step3 .order.card .value {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
  color: #4d5e55;
}
.create-order-page .step3 .order.card .remove,
.create-orderguide-page .step3 .order.card .remove {
  position: absolute;
  padding: 10px 8px;
  top: -7px;
  right: 3px;
  opacity: 0.7;
  cursor: pointer;
}
.create-order-page .step3 .finito,
.create-orderguide-page .step3 .finito {
  text-align: center;
}
.create-order-page .step3 .finito > .flex,
.create-orderguide-page .step3 .finito > .flex {
  margin: 4px;
  justify-content: space-between;
  align-items: center;
}
.create-order-page .step3 .finito > .flex .title,
.create-orderguide-page .step3 .finito > .flex .title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
}
.create-order-page .step3 .finito > .flex .value,
.create-orderguide-page .step3 .finito > .flex .value {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  /* or 28px */
  display: flex;
  align-items: center;
  /* Text/01 */
  color: #4d5e55;
}
.create-order-page .step3 .finito .btn,
.create-orderguide-page .step3 .finito .btn {
  margin-top: 25px;
}
.create-order-page .step4,
.create-orderguide-page .step4 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
}
.create-order-page .step4 .bg,
.create-orderguide-page .step4 .bg {
  width: 100%;
  height: 100%;
  background-color: #00000033;
  justify-content: center;
}
.create-order-page .step4 .bg .modal,
.create-orderguide-page .step4 .bg .modal {
  align-self: center;
  width: 660px;
  height: 440px;
  background-color: #fff;
  padding: 80px;
  text-align: center;
  border-radius: 10px;
}
.create-order-page .step4 .bg .modal img,
.create-orderguide-page .step4 .bg .modal img {
  width: 100px;
  margin-bottom: 1.3rem;
}
.create-order-page .step4 .bg .modal p,
.create-orderguide-page .step4 .bg .modal p {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: #4d5e55;
  margin-bottom: 0.4rem;
}
.create-order-page .step4 .bg .modal .btn,
.create-orderguide-page .step4 .bg .modal .btn {
  margin-top: 35px;
}

.go-back {
  cursor: pointer;
}

.ant-col {
  position: static;
}

.ant-menu-submenu-open.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > *,
.ant-menu-submenu-active:hover
  .ant-menu-submenu-title
  > .ant-menu-submenu-arrow {
  color: white;
}

.ant-steps {
  max-width: 300px;
}
.ant-steps .ant-steps-item {
  padding: 10px !important;
}
.ant-steps .ant-steps-item.ant-steps-item-wait {
  opacity: 0.4;
}
.ant-steps
  .ant-steps-item.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title:after {
  background-color: #97be11;
  top: 9px;
  left: 0;
  right: 0;
}
.ant-steps .ant-steps-item .ant-steps-item-icon {
  width: 18px;
  height: 18px;
  background: #97be11;
  border: none;
  box-shadow:
    0px 0px 0px 2px white,
    0px 0px 0px 3px #97be11;
}
.ant-steps .ant-steps-item .ant-steps-item-icon .ant-steps-icon {
  color: #00000000 !important;
}

.quantities .value span {
  margin-right: 15px;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-input[disabled] {
  color: #00000095;
}

.delete-modal .bg {
  display: flex;
  z-index: 20;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000033;
  justify-content: center;
}
.delete-modal .bg .modal {
  width: 660px;
  height: 300px;
  padding: 50px;
  align-self: center;
  text-align: center;
  border-radius: 10px;
  background: white;
  color: #4d5e55;
}
.delete-modal .bg .modal h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  margin: 20px;
  color: #4d5e55;
}
.delete-modal .bg .modal p {
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  margin-bottom: 40px;
  color: #4d5e55;
}
.delete-modal .bg .modal .btn {
  margin: 30px;
  margin-bottom: 0;
}

.info-page {
  position: relative;
  min-height: 90vh;
}

.dashboard .photo-card {
  background: white;
  width: 320px;
  height: 115px;
  margin: 12px;
  border: 0.2px solid #55585b98;
  border-radius: 10px;
  box-shadow: 10px 10px 21px -12px rgba(0, 0, 0, 0.55);
  overflow: hidden;
}
.dashboard .photo-card .card {
  margin: 0;
  border: none;
  box-shadow: none;
  width: 100%;
}
.dashboard .photo-card .photo {
  height: 192px;
  background: url(/public/images/fd.jpg);
  background-size: cover;
}
.dashboard .photo-card.new .photo {
  margin: 14px;
  margin-top: 0;
  height: 173px;
  background: url(/public/images/duohd.jpg);
  background-size: cover;
}
.dashboard .card {
  width: 318px;
  height: 111px;
  border: 0.2px solid #55585b98;
  border-radius: 10px;
  padding: 24px;
  justify-content: space-between;
  box-shadow: 10px 10px 21px -12px rgba(0, 0, 0, 0.55);
  margin: 12px;
  background: white;
}
.break {
  flex-basis: 100%;
  height: 0;
}
.dashboard .newsCard {
  width: 100%;
  border: 0.2px solid #55585b98;
  border-radius: 10px;
  padding: 24px;
  justify-content: space-between;
  box-shadow: 10px 10px 21px -12px rgba(0, 0, 0, 0.55);
  margin: 12px;
  background: white;
  flex-wrap: wrap;
}
.dashboard .card .text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dashboard .card .text .number {
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #0e5130;
}
.dashboard .card .text .title {
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #4d5e55;
}
.dashboard .newsCard .text .title {
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #4d5e55;
}
.dashboard .card .icon img {
  width: 32px;
}
.dashboard .add-order {
  display: inline-block;
  cursor: pointer;
  border-radius: 2px;
  padding: 8px 60px;
  background: #eb7120;
  height: auto;
  color: white;
  border: none;
  margin: 12px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  width: 204px;
  height: 54px;
  padding: 16px 30px;
  border-radius: 10px;
}
.dashboard .add-order:hover {
  background: #eb7120;
  opacity: 0.9;
  color: white;
}
.dashboard .add-order img {
  width: 16px;
  margin-bottom: 3px;
}

.ant-spin.ant-spin-spinning,
.ant-spin-nested-loading {
  min-height: 100%;
  opacity: 1;
  background-color: #f0f2f5;
}

.not_this .ant-spin.ant-spin-spinning,
.not_this .ant-spin-nested-loading,
.info-page .ant-spin.ant-spin-spinning,
.info-page .ant-spin-nested-loading {
  min-height: unset;
  opacity: 1;
  background-color: #f0f2f5;
}

.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding: 1px;
}

.quantity input[type='number'] {
  width: 40px;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}
.quantity input[type='number']::-webkit-inner-spin-button,
.quantity input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.quantity input[type='number']::-webkit-inner-spin-button,
.quantity input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.edit_profile_upload {
  align-items: center;
  display: flex;
  width: 100%;
}
.edit_profile_upload .ant-upload {
  width: 96%;
}
.edit_profile_upload button {
  justify-content: center;
}
.height-40 {
  height: 40px !important;
}
.height-35 {
  height: 35px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.edit_profile_button {
  width: 96%;
  display: flex;
  align-items: center;
}
@media print {
  .remove-onPrint {
    display: none;
  }
}
@page {
  size: landscape;
}
.stack-on-tablet {
  flex-direction: row;
}
@media (max-width: 1200px) {
  .stack-on-tablet {
    flex-direction: column;
  }
}
