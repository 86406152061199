@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

$grey-text: #5e5e5e;
$dark-green: #4d5e55;
$orange: #eb7120;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	// max-height: 100%;
}

body {
	font-family: Roboto;
}

.flex {
	display: flex;
}

img {
	max-width: 100%;
}

h2.title {
	margin: 24px 0;
	color: #4d5e55;

	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 120%;

	&.go-back img {
		margin-top: -8px;
	}
}

.no-auth-page form {
	input[type="text"],
	.ant-picker,
	textarea.ant-input,
	.ant-input-password,
	.ant-input-number-input,
	.ant-form-item .ant-cascader-picker,
	.ant-form-item .ant-select,
	.ant-input-number {
		max-width: unset;
		width: 100%;
		// height: 48px;
		border-radius: 6px;
	}
}

.order-info-page {
	input[type="text"],
	.ant-picker,
	textarea.ant-input,
	.ant-input-password,
	.ant-input-number-input,
	.ant-form-item .ant-cascader-picker,
	.ant-form-item .ant-select,
	.ant-select-selector,
	.ant-input-number {
		width: 100%;
	}
}

form {
	label {
		font-weight: normal;
		font-size: 14px;
		line-height: 140%;
		
	}
 	
	.ant-form-item {
		margin-bottom: 16px;
		.ant-form-item-label {
			padding-bottom: 1px;
		}
	}

	input[type="text"],
	.ant-picker,
	textarea.ant-input,
	.ant-input-password,
	.ant-input-number-input,
	.ant-form-item .ant-cascader-picker,
	.ant-form-item .ant-select,
	.ant-select-selector,
	.ant-input-number {
		max-width: unset;
		width: 96%;
		// height: 48px;
		border-radius: 6px !important;
	}

	.ant-form-item-control-input-content .ant-select-selector {
		width: 100%;
	}

	.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border-radius: 6px;
	}

	.ant-checkbox-wrapper {
		font-size: 12px;
		line-height: 140%;
		width: 100%;
	}

	.ant-form-item-has-success {
		margin-bottom: 10px;
	}

	button {
		background-color: $orange;
		border: none;

		&:disabled,
		&[disabled] {
			border: 1px solid #999999;
			background-color: #cccccc;
			color: #666666;
		}

		img {
			width: 24px;
		}
	}
}

.clickable {
	cursor: pointer;
}

.btn-gp {
	position: absolute;
	bottom: 0px;
	right: 0px;
}

.btn {
	display: inline-block;
	cursor: pointer;
	border-radius: 2px;
	padding: 8px 60px;
	background: $orange;
	height: auto;
	color: white;
	border: none;
	margin: 5px;

	&:hover {
		background: $orange;
		opacity: 0.9;
		color: white;
	}

	&:disabled,
	&[disabled] {
		border: 1px solid #999999 !important;
		background-color: #cccccc !important;
		color: #666666 !important;
	}

	&.outline {
		border: 1px solid #e01d11;
		background-color: white;
		color: #e01d11;

		&:hover {
			border: 1px solid #e01d11;
			background-color: white;
			color: #e01d11;
			opacity: 0.9;
		}
	}
}

.empty-table {
	min-height: 500px;
	width: 100%;
	height: 100vh;
	color: #4d5e55;
	display: flex;
	justify-content: center;

	.wrapper {
		width: auto;
		margin-top: 25vh;
		text-align: center;
	}
}

.no-auth-page {
	height: 100vh;
	overflow: hidden;

	.sider {
		width: 504px;
		height: 100vh;
	}

	form {
		button {
			font-weight: normal;
			font-size: 20px;
			line-height: 140%;
			margin-top: 30px;
			width: 100%;
			height: 60px;
			border-radius: 6px;
		}
	}

	.other {
		padding: 20px 110px;
		position: relative;
		flex: 1;

		.header {
			text-align: right;
			font-weight: normal;
			font-size: 16px;
			line-height: 140%;

			.link a {
				color: #0e5130;
				font-weight: bold;
				font-size: 16px;
				line-height: 140%;
			}
		}

		.content {
			height: 100%;
			width: 548px;

			.wrapper {
				align-self: center;
				width: 100%;

				h1 {
					color: $dark-green;
					font-weight: bold;
					font-size: 56px;
					line-height: 110%;
				}

				p {
					color: $grey-text;
					font-weight: normal;
					font-size: 16px;
					line-height: 140%;
				}
			}
		}
	}
}

.avatar {
	position: absolute;
	top: 24px;
	right: 54px;
	padding: 0 20px;
	height: 30px;
	border-radius: 15px;
	overflow: hidden;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	font-size: 16px;
	line-height: 140%;
	color: white;

	text-shadow: 0px 0px 2px #00000054;
}

#sign-in {
	.forget a {
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 140%;
		color: #4d4d4d;
	}

	.ant-checkbox-wrapper span {
		color: #000000;
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 140%;
	}

	.forget {
		justify-content: space-between;

		.ant-form-item {
			width: 200px;
		}
	}
}

.ant-layout-sider-collapsed .ant-layout-sider-children #sider {
	.ant-menu-item-icon img {
		display: inline-block;
		padding-bottom: 6px;
	}

	.ant-menu-item-icon {
		width: 30px;
	}

	.logo {
		width: 70px;
		margin-bottom: 60px;
	}
}

#sider {
	background: #97be11;
	font-size: 17px;
	line-height: 140%;
	overflow: hidden;
	color: white;

	.ant-menu-item-icon {
		width: 30px;
	}

	.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
		font-size: 16px;
	}

	.logo {
		width: 90px;
		margin: 10px auto 40px auto;
	}

	ul.ant-menu {
		border: none;
		color: white;

		li {
			background: #97be11;
			position: relative;

			&.ant-menu-item-selected .ant-menu-title-content * {
				font-weight: 900;
				font-size: 18px;
				// text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.9);

				&::before {
					opacity: 0;
					content: "";
					position: absolute;
					width: 17px;
					height: 24px;
					background: #4d5e55;
					left: 0;
					top: 8px;
					border-radius: 0 4px 4px 0;
					// opacity: 0;
				}
			}

			.ant-menu-submenu-title {
				color: white;
				opacity: 1;
				font-size: 16px;
				// text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.8);

				:hover {
					color: white;
				}
			}

			.ant-menu-title-content * {
				color: white;
				opacity: 1;
				// text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.8);
			}

			ul.ant-menu-sub {
				list-style: inside;
				font-size: 12px;
				line-height: 140%;
				margin-bottom: 10px;

				li {
					margin: 0;
					height: 30px;
				}
			}
		}
	}

	ul {
		.ant-menu-submenu {
			border: none;
			color: white;

			ul.ant-menu {
				li.ant-menu-item-selected::before {
					content: "";
					position: absolute;
					width: 17px;
					height: 18px;
					background: #97be11;
					left: 0;
					top: 6px;
					border-radius: 0 4px 4px 0;
				}

				.ant-menu-submenu-title:hover {
					color: white;
				}

				.ant-menu-title-content * {
					color: white;
				}
			}
		}
	}
}

.ant-layout-sider-collapsed #sider .ant-menu-item-icon {
	width: 20px;
	li.ant-menu-item-selected::before {
		content: "";
		position: absolute;
		width: 32px;
	}
}

.ant-table {
	border-radius: 20px 20px 0 0;
	overflow: hidden;
	border: 1px solid #55555545;

	.ant-table-container {
		.ant-table-thead {
			th {
				font-size: 12px;
				line-height: 140%;
				padding: 12px 16px;
				color: #c9c8d3;
				background: white;
			}
		}

		.ant-table-tbody.ant-table-tbody {
			tr {
				td {
					font-size: 14px;
					line-height: 140%;
					color: #4d5e55;
					padding: 10px;
				}

				&:nth-child(odd) {
					background: rgba(151, 190, 17, 0.1);

					.ant-table-cell.ant-table-column-sort {
						background: rgba(151, 190, 17, 0.1);
					}
				}
			}
		}
	}
}

#users-page {
	.cards {
		margin-bottom: 20px;

		.user-type-card {
			justify-content: space-between;
			padding: 24px;
			width: 318px;
			border-radius: 10px;
			color: #0e5130;
			background-color: white;
			margin-right: 24px;
			border: 1px solid #97be1155;
			box-shadow: 7px 7px 11px -5px rgba(0, 0, 0, 0.2);

			&.active {
				background-color: #97be11;
				color: white;

				.type {
					color: white;
				}
			}

			.number {
				font-style: normal;
				font-weight: bold;
				font-size: 32px;
				line-height: 120%;
			}

			.type {
				font-weight: bold;
				font-size: 16px;
				line-height: 140%;
				color: #4d5e55;
			}
		}
	}
}

.info-page {
	h2 {
		img {
			height: 24px;
			margin-right: 10px;
		}
	}
}

.every-info {
	margin: 20px 0;

	.title {
		font-family: Roboto;
		font-style: bold;
		font-weight: normal;
		font-size: 14px;
		line-height: 140%;
		/* or 20px */

		display: flex;
		align-items: center;

		/* Text/Black */

		color: #000000;
	}

	.text {
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 140%;
		/* or 22px */

		display: flex;
		align-items: center;

		/* Text/01 */

		color: #4d5e55;
	}
}

.order-info {
	.card {
		background-color: white;
		border-radius: 10px;
		padding: 16px;

		.btn {
			margin-bottom: 5px;
		}
	}
}

.ant-btn-primary {
	background-color: $orange;
	border: none;

	&:hover {
		background-color: $orange;
	}

	&[disabled] {
		background-color: #c1c1c1;
		color: white;
		border: none;
	}
}

.ant-spin-container {
	position: static;

	&::after {
		position: static;
	}
}

.ant-form-item-control-input,
.ant-spin-nested-loading {
	position: static;
}

.create-order-page,
.create-orderguide-page {
	.main {
		flex: 5;

		.title {
			img {
				height: 24px;
				margin-right: 10px;
			}
		}

		.steps {
			margin-bottom: 30px;
		}
	}

	.order-list,
	.product-list {
		z-index: 10;
		flex: 2;
		margin-right: -27px;
		margin-left: 20px;
		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
		height: 100vh;
		display: flex;
		flex-direction: column;

		& > .title {
			padding: 25px 16px;
			background-color: #4d5e55;
			color: white;
			border-radius: 0px 0px 5px 5px;

			font-family: Roboto;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 120%;
		}

		.products,
		.orders {
			flex: 1;
			overflow-y: scroll;
			overflow-x: hidden;
			// align-items: flex-start;
			flex-direction: column;
			flex-wrap: nowrap;

			.product.card,
			.order.card {
				position: relative;
				// max-height: 110px;
				padding: 10px 5px;
				width: 95%;
				margin: 5px auto;
				background: rgba(151, 190, 17, 0.06);
				/* Stroke/Focused */
				border: 0.2px solid #97be11;
				box-sizing: border-box;
				border-radius: 6px;

				.title {
					font-style: normal;
					font-weight: 300;
					font-size: 10px;
					line-height: 12px;
				}

				.value {
					font-style: normal;
					font-weight: bold;
					font-size: 12px;
					line-height: 140%;
				}

				.remove {
					position: absolute;
					padding: 5px 3px;
					top: -7px;
					right: 3px;
					opacity: 0.7;
					cursor: pointer;
				}
			}
		}

		.bottom {
			// text-align: center;
			height: auto;
			flex-direction: column;

			hr {
				width: 90%;
				border: none;
				border-bottom: 1px solid grey;
			}

			.price {
				padding: 2px 8px;
				display: flex;
				justify-content: space-between;

				.title {
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 140%;
				}

				.value {
					font-style: normal;
					font-weight: bold;
					font-size: 16px;
					line-height: 140%;

					/* Text/01 */

					color: #4d5e55;
				}
			}
		}
	}

	.step2 {
		button {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 140%;

			width: 85px;
			height: 28px;
			padding: 0;
			margin: 0;
		}
	}

	.step3 {
		.order.card {
			position: relative;
			max-height: 100px;
			padding: 10px 5px;
			width: 95%;
			margin: 15px auto;
			background: rgba(151, 190, 17, 0.06);
			/* Stroke/Focused */
			border: 0.2px solid #97be11;
			box-sizing: border-box;
			border-radius: 6px;

			.title {
				font-style: normal;
				font-weight: 300;
				font-size: 10px;
				line-height: 12px;
			}

			.value {
				font-style: normal;
				font-weight: bold;
				font-size: 12px;
				line-height: 140%;
				color: #4d5e55;
			}

			.remove {
				position: absolute;
				padding: 10px 8px;
				top: -7px;
				right: 3px;
				opacity: 0.7;
				cursor: pointer;
			}
		}

		.finito {
			text-align: center;

			& > .flex {
				margin: 4px;
				justify-content: space-between;
				align-items: center;

				.title {
					font-family: Roboto;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 140%;
				}

				.value {
					font-family: Roboto;
					font-style: normal;
					font-weight: bold;
					font-size: 20px;
					line-height: 140%;
					/* or 28px */

					display: flex;
					align-items: center;

					/* Text/01 */

					color: #4d5e55;
				}
			}

			.btn {
				margin-top: 25px;
			}
		}
	}

	.step4 {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 20;

		.bg {
			width: 100%;
			height: 100%;
			background-color: #00000033;
			justify-content: center;

			.modal {
				align-self: center;
				width: 660px;
				height: 440px;
				background-color: #fff;
				padding: 80px;
				text-align: center;
				border-radius: 10px;

				img {
					width: 100px;
					margin-bottom: 1.3rem;
				}

				p {
					font-style: normal;
					font-weight: bold;
					font-size: 24px;
					text-align: center;
					color: #4d5e55;
					margin-bottom: 0.4rem;
				}

				.btn {
					margin-top: 35px;
				}
			}
		}
	}
}

.go-back {
	cursor: pointer;
}

.ant-col {
	position: static;
}

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > *,
.ant-menu-submenu-active:hover .ant-menu-submenu-title > .ant-menu-submenu-arrow {
	color: white;
}

.ant-steps {
	max-width: 300px;

	.ant-steps-item {
		padding: 10px !important;

		&.ant-steps-item-wait {
			opacity: 0.4;
		}

		&.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
			background-color: #97be11;
			top: 9px;
			left: 0;
			right: 0;
		}

		.ant-steps-item-icon {
			width: 18px;
			height: 18px;

			background: #97be11;
			border: none;

			box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 1), 0px 0px 0px 3px #97be11;

			.ant-steps-icon {
				color: #00000000 !important;
			}
		}
	}
}

.quantities {
	.value {
		span {
			margin-right: 15px;
		}
	}
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input[disabled] {
	color: #00000095;
}

.delete-modal {
	.bg {
		display: flex;
		z-index: 20;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #00000033;
		justify-content: center;

		.modal {
			width: 660px;
			height: 300px;
			padding: 50px;
			align-self: center;
			text-align: center;
			border-radius: 10px;
			background: white;
			color: #4d5e55;

			h3 {
				font-weight: bold;
				font-size: 24px;
				line-height: 120%;
				margin: 20px;
				color: #4d5e55;
			}

			p {
				font-weight: bold;
				font-size: 20px;
				line-height: 120%;
				margin-bottom: 40px;
				color: #4d5e55;
			}

			.btn {
				margin: 30px;
				margin-bottom: 0;
			}
		}
	}
}

.info-page {
	position: relative;
	min-height: 90vh;
}

.dashboard {
	.photo-card {
		background: white;
		width: 432px;
		height: 300px;
		margin: 12px;
		border: 0.2px solid #55585b98;
		border-radius: 10px;
		box-shadow: 10px 10px 21px -12px rgba(0, 0, 0, 0.55);
		overflow: hidden;

		.card {
			margin: 0;
			border: none;
			box-shadow: none;
			width: 100%;
		}

		.photo {
			height: 192px;
			background: url(/public/images/fd.jpg);
			background-size: cover;
		}

		&.new {
			.photo {
				margin: 14px;
				margin-top: 0;
				height: 173px;
				// width: ;
				background: url(/public/images/duohd.jpg);
				background-size: cover;
			}
		}
	}

	.card {
		width: 318px;
		height: 111px;
		border: 0.2px solid #55585b98;
		border-radius: 10px;
		padding: 24px;
		justify-content: space-between;
		box-shadow: 10px 10px 21px -12px rgba(0, 0, 0, 0.55);
		margin: 12px;
		background: white;

		.text {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.number {
				font-weight: bold;
				font-size: 32px;
				line-height: 120%;
				display: flex;
				align-items: center;
				color: #0e5130;
			}
			.title {
				font-weight: bold;
				font-size: 16px;
				line-height: 140%;
				color: #4d5e55;
			}
		}

		.icon {
			img {
				width: 32px;
			}
		}
	}

	.add-order {
		display: inline-block;
		cursor: pointer;
		border-radius: 2px;
		padding: 8px 60px;
		background: $orange;
		height: auto;
		color: white;
		border: none;
		// margin: 5px;

		&:hover {
			background: $orange;
			opacity: 0.9;
			color: white;
		}

		// align-content: center;
		margin: 12px;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		// line-height: 140%;
		width: 204px;
		height: 54px;
		padding: 16px 30px;
		// color: #eb7120;
		// border: 0.6px solid #eb7120;
		border-radius: 10px;
		// background-color: white;

		img {
			width: 16px;
			margin-bottom: 3px;
		}
	}
}

.ant-spin.ant-spin-spinning,
.ant-spin-nested-loading {
	min-height: 100%;
	opacity: 1;
	background-color: #f0f2f5;
}

.not_this,
.info-page {
	.ant-spin.ant-spin-spinning,
	.ant-spin-nested-loading {
		min-height: unset;
		opacity: 1;
		background-color: #f0f2f5;
	}
}

.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
	padding: 1px;
}

.quantity {
	input[type="number"] {
		width: 40px;
		-moz-appearance: textfield;
		-webkit-appearance: textfield;
		appearance: textfield;
		text-align: center;
	}
	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}
}
